import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppTopNavComponent } from './layout/top-nav/top-nav.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TabBarComponent } from './layout/tab-bar/tab-bar.component';
import { SharedModule } from '../shared/shared.module';
import { PracticeSwitcherComponent } from './layout/practice-switcher/practice-switcher.component';
import { AppMenuComponent } from './layout/app-menu/app-menu.component';
import { HelpMenuComponent } from './layout/help-menu/help-menu.component';
import { NotificationsMenuComponent } from './layout/notifications-menu/notifications-menu.component';
import { UserMenuComponent } from './layout/user-menu/user-menu.component';
import { PatientSearchComponent } from './layout/search/search.component';
import { DraftOverlayDraggableComponent } from './layout/draft-overlay/draft-overlay-draggable/draft-overlay-draggable.component';
import { PatientsSharedComponentsModule } from '../modules/patients-shared-components/patients-shared-components.module';
import { NgCdkModule } from '../ng-cdk.module';
import { AuthenticatedComponent } from './layout/authenticated/authenticated.component';
import { PublicComponent } from './layout/public/public.component';
import {HomeComponent} from './layout/home/home.component';
import {ErrorComponent} from './layout/error/error.component';
import { IdleTimeoutDirectiveDirective } from './directives/idle-timeout-directive.directive';
import {MatListModule} from '@angular/material/list';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {MatTableModule} from '@angular/material/table';
import {MatSortModule} from '@angular/material/sort';
import {MatLineModule, MatRippleModule} from '@angular/material/core';
import {MatTabsModule} from '@angular/material/tabs';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatMenuModule} from '@angular/material/menu';



@NgModule({
    declarations: [
        AppTopNavComponent,
        TabBarComponent,
        PracticeSwitcherComponent,
        AppMenuComponent,
        HelpMenuComponent,
        NotificationsMenuComponent,
        UserMenuComponent,
        PatientSearchComponent,
        DraftOverlayDraggableComponent,
        AuthenticatedComponent,
        PublicComponent,
        HomeComponent,
        ErrorComponent,
        IdleTimeoutDirectiveDirective,
    ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    NgCdkModule,
    SharedModule,
    PatientsSharedComponentsModule,
    ReactiveFormsModule,
    MatListModule,
    MatIconModule,
    MatButtonModule,
    MatInputModule,
    MatTableModule,
    MatSortModule,
    MatRippleModule,
    MatTabsModule,
    MatToolbarModule,
    MatMenuModule,
    MatLineModule,
  ],
    providers: [],
    exports: [
        CommonModule,
        AppTopNavComponent,
        TabBarComponent,
        PracticeSwitcherComponent,
        AppMenuComponent,
        HelpMenuComponent,
        NotificationsMenuComponent,
        UserMenuComponent,
        PatientSearchComponent,
        DraftOverlayDraggableComponent,
        AuthenticatedComponent,
        PublicComponent,
        HomeComponent,
        ErrorComponent,
        IdleTimeoutDirectiveDirective
    ]
})
export class CoreModule { }
