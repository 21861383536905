import {Component, OnDestroy, OnInit, Renderer2, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {DraftStatus} from 'src/app/modules/patient-drafts-repository/entities/draft';
import {DraftsService, PatientDraft} from '../../../services/drafts.service';
import {DraftInteractionBehaviour} from '../draft-interaction-behaviour';
import {Permissions} from '../../../auth/permissions';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {MatBottomSheet} from '@angular/material/bottom-sheet';

@Component({
  selector: 'ui-draft-overlay-draggable', templateUrl: './draft-overlay-draggable.component.html', styleUrls: ['./draft-overlay-draggable.component.scss']
})
export class DraftOverlayDraggableComponent implements OnInit, OnDestroy {

  drafts: PatientDraft[] = [];
  isMobile: boolean;
  hasOpenDrafts = false;
  @ViewChild('draftComment') draftCommentComponent: DraftInteractionBehaviour;
  @ViewChild('draftScript') draftScriptComponent: DraftInteractionBehaviour;
  @ViewChild('draftReport') draftReportComponent: DraftInteractionBehaviour;
  @ViewChild('draftEmail') draftEmailComponent: DraftInteractionBehaviour;
  @ViewChild('draftMessage') draftMessageComponent: DraftInteractionBehaviour;
  readonly Permissions = Permissions;
  private draftEventSubscription: Subscription;
  private breakpointObserverSubscription: Subscription;

  constructor(
    private draftService: DraftsService,
    private renderer: Renderer2,
    private breakpointObserver: BreakpointObserver
  ) {
  }

  get draftStatus(): typeof DraftStatus {
    return DraftStatus;
  }

  ngOnInit(): void {
    this.draftEventSubscription = this.draftService.events$.subscribe(() => {
      this.drafts = this.draftService.getDrafts();
      this.updateOpenDrafts();
    });
    this.breakpointObserverSubscription = this.breakpointObserver.observe([Breakpoints.Handset]).subscribe(result => {
      this.isMobile = result.matches;
      this.updateOpenDrafts();
    });
  }

  ngOnDestroy(): void {
    this.draftEventSubscription?.unsubscribe();
    this.breakpointObserverSubscription?.unsubscribe();
  }

  minimizeAllDrafts() {
    this.draftService.minimizeAllDrafts();
    this.updateOpenDrafts();
  }

  minimizeDraft(index: number) {
    this.draftService.minimizeDraft(index);
    this.updateOpenDrafts();
  }

  maximizeDraft(index: number) {
    this.draftService.maximizeDraft(index);
    this.updateOpenDrafts();
  }

  restoreDraft(index: number) {
    this.draftService.restoreDraft(index);
    this.updateOpenDrafts();
  }

  closeDraft(index: number) {
    this.draftService.closeDraft(index);
    this.updateOpenDrafts();
  }

  private updateOpenDrafts() {
    const openDrafts = this.drafts.filter(d => (d.draftStatus === DraftStatus.OPEN || d.draftStatus === DraftStatus.MAXIMIZED));
    this.hasOpenDrafts = openDrafts.length > 0;
    if (openDrafts.length > 0 && this.isMobile) {
      this.renderer.addClass(document.body, 'lock-scroll');
    } else {
      this.renderer.removeClass(document.body, 'lock-scroll');
    }
  }
}
