<div class="drafts-holder" [class.mobile]="isMobile" [class.has-open-drafts]="hasOpenDrafts">
    <ul class="drafts justify-content-end">
        <li class="draft-item" *ngFor="let draft of drafts; let i = index;" [class.maximized]="draft.draftStatus === draftStatus.MAXIMIZED" [class.minimized]="draft.draftStatus === draftStatus.MINIMIZED">
            <div class="draft mat-elevation-z6" cdkDragBoundary=".drafts-holder" cdkDrag [cdkDragDisabled]="draft.draftStatus === draftStatus.MAXIMIZED || draft.draftStatus === draftStatus.MINIMIZED || isMobile">
                <div class="draft-header d-flex flex-row align-items-center justify-content-between" [class.draggable]="draft.draftStatus === draftStatus.OPEN" cdkDragHandle>
                    <span class="text-truncate text-light">{{draft.draftTitle}} </span>
                    <div class="mat-button-group ms-3 text-light">
                        <button mat-icon-button class="mini-icon-button" *ngIf="draft.draftStatus === draftStatus.MINIMIZED" (click)="restoreDraft(draft.index)"><mat-icon>maximize</mat-icon></button>
                        <button mat-icon-button class="mini-icon-button" *ngIf="draft.draftStatus !== draftStatus.MINIMIZED" (click)="minimizeDraft(draft.index)"><mat-icon>minimize</mat-icon></button>
                        <button mat-icon-button class="mini-icon-button" *ngIf="draft.draftStatus !== draftStatus.MAXIMIZED" (click)="maximizeDraft(draft.index)"><mat-icon>open_in_full</mat-icon></button>
                        <button mat-icon-button class="mini-icon-button" *ngIf="draft.draftStatus === draftStatus.MAXIMIZED" (click)="restoreDraft(draft.index)"><mat-icon>close_fullscreen</mat-icon></button>
                        <button mat-icon-button class="mini-icon-button" (click)="closeDraft(draft.index)"><mat-icon>close</mat-icon></button>
                    </div>
                </div>
                <div class="draft-body">
                    <app-create-comment *ngIf="draft.draftType.toLowerCase() === 'comment'" [draft]="draft" #draftComment></app-create-comment>
                    <app-create-email *ngIf="draft.draftType.toLowerCase() === 'email'" [draft]="draft" #draftEmail></app-create-email>
                    <app-create-message *ngIf="draft.draftType.toLowerCase() === 'message'" [draft]="draft" #draftMessage></app-create-message>
                    <app-create-report *ngIf="draft.draftType.toLowerCase() === 'report'" [draft]="draft" #draftReport></app-create-report>
                    <app-create-script *ngIf="draft.draftType.toLowerCase() === 'script'" [draft]="draft" #draftScript></app-create-script>
                </div>
              <div class="draft-footer">
                <button mat-flat-button color="primary" [disabled]="!draftCommentComponent?.saveButtonEnabled"
                        appConfirmationDialog [confirmMessage]="'Are you sure you want to save this comment?'" (confirm)="draftCommentComponent?.save()"
                        *appPermissionRequired="Permissions.ClinicalCommentsWrite; condition: draft.draftType.toLowerCase() === 'comment'">
                  Save Comment
                </button>
                <button mat-flat-button color="primary" [disabled]="!draftEmailComponent?.saveButtonEnabled"
                        appConfirmationDialog [confirmMessage]="'Are you sure you want to send this email?'" (confirm)="draftEmailComponent?.save()"
                        *appPermissionRequired="Permissions.ClinicalEmailsWrite; condition: draft.draftType.toLowerCase() === 'email'">
                  Send Email
                </button>
                <button mat-flat-button color="primary" [disabled]="!draftMessageComponent?.saveButtonEnabled"
                        appConfirmationDialog [confirmMessage]="'Are you sure you want to send this message?'" (confirm)="draftMessageComponent?.save()"
                        *appPermissionRequired="Permissions.ClinicalMessagesWrite condition: draft.draftType.toLowerCase() === 'message'">
                  Send Message
                </button>
                <button mat-flat-button color="primary" [disabled]="!draftReportComponent?.saveButtonEnabled"
                        appConfirmationDialog [confirmMessage]="'Are you sure you want to save this report?'" (confirm)="draftReportComponent?.save()"
                        *appPermissionRequired="Permissions.ClinicalReportsWrite; condition: draft.draftType.toLowerCase() === 'report'">
                  Save Report
                </button>
                <button mat-flat-button color="primary" [disabled]="!draftScriptComponent?.saveButtonEnabled"
                        appConfirmationDialog [confirmMessage]="'Are you sure you want to save this script?'" (confirm)="draftScriptComponent?.save()"
                        *appPermissionRequired="Permissions.ClinicalScriptsWrite; condition: draft.draftType.toLowerCase() === 'script'">
                  Save Script
                </button>
              </div>
            </div>
        </li>
    </ul>
</div>

